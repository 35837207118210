// Package imports:
import React from 'react';
// Component imports:
import Table from '../../ui-elements/Table/Table';
import Button from '../../ui-elements/Button/Button';
import HeadSection from '../../ui-elements/HeadSection/HeadSection';
import BulletList from '../../ui-elements/Lists/BulletList';

interface IAdData {
    name: string,
    location: string,
    size: string,
    price1Week: string,
    price4Week: string,
    maxAdvertisers: number,
};

const Advertise: React.FC = () => {
    const dataAdOne: IAdData[] = [{
        name: "Langi*",
        location: "Efst á forsíðu",
        size: "1360x180px",
        price1Week: "90.000kr",
        price4Week: "280.000kr",
        maxAdvertisers: 6,
    }, {
        name: "Kubbur A",
        location: "Fyrir neðan hlutabréf",
        size: "310x400px",
        price1Week: "45.000kr",
        price4Week: "140.000kr",
        maxAdvertisers: 6,
    }, {
        name: "Kubbur B",
        location: "Fyrir neðan sjóði",
        size: "310x400px",
        price1Week: "45.000kr",
        price4Week: "140.000kr",
        maxAdvertisers: 6,
    }, {
        name: "Kubbur C",
        location: "Fyrir neðan gjaldmiðla",
        size: "310x400px",
        price1Week: "30.000kr",
        price4Week: "90.000kr",
        maxAdvertisers: 6,
    }, {
        name: "Kubbur D",
        location: "Fyrir neðan séreignarsjóði",
        size: "310x400px",
        price1Week: "30.000kr",
        price4Week: "90.000kr",
        maxAdvertisers: 6,
    }, {
        name: "Litli",
        location: "Á eftir sjöttu frétt",
        size: "400x80px",
        price1Week: "45.000kr",
        price4Week: "140.000kr",
        maxAdvertisers: 6,
    }, {
        name: "Kubbur X",
        location: "Á eftir 15. frétt",
        size: "300x250px",
        price1Week: "45.000kr",
        price4Week: "140.000kr",
        maxAdvertisers: 8,
    }, {
        name: "Kubbur Y",
        location: "Fyrir neðan fréttir",
        size: "300x250px",
        price1Week: "45.000kr",
        price4Week: "140.000kr",
        maxAdvertisers: 8,
    }];

    const dataAdTwo: IAdData[] = [{
        name: "Þykki A",
        location: "Efst á markaðssíðu",
        size: "1018x360px",
        price1Week: "35.000kr",
        price4Week: "110.000kr",
        maxAdvertisers: 6,
    }, {
        name: "Þykki B",
        location: "Fyrir miðri markaðssíðu",
        size: "1018x360px",
        price1Week: "25.000kr",
        price4Week: "80.000kr",
        maxAdvertisers: 6,
    }, {
        name: "Kubbur E",
        location: "Til hægri á fyrirtækjasíðum og neðst á markaðssíðum",
        size: "310x400px",
        price1Week: "25.000kr",
        price4Week: "80.000kr",
        maxAdvertisers: 6,
    }];

    const dataAdThree: IAdData[] = [{
        name: "Litli app",
        location: "Fyrir ofan fréttir",
        size: "300x250",
        price1Week: "25.000kr",
        price4Week: "80.000kr",
        maxAdvertisers: 6,
    }];

    return (
        <div className='main KCL_Auglysingar'>
            <div className="main__inner-fluid grid-items">
                <div className="mini_shell">
                    <HeadSection
                        title="Auglýstu á Keldunni"
                        summary={
                            <p>Keldan.is er upplýsingaveita atvinnulífsins og mikið notuð sem vinnutæki þar sem notendur hafa forsíðuna opna allan vinnudaginn.</p>
                        }
                        description={
                            <BulletList
                                items={[
                                    'Flettingar eru fleiri en 200.000 á viku.',
                                    '130.000 notendur heimsækja Kelduna á mánuði.',
                                    '18.000 manns hafa sótt Keldu appið.'
                                ]}
                            />
                        }
                        actions={
                            <Button
                                buttonType="primary"
                                size="lg"
                                anchorProps={{ href: '/Hafa-Samband/Auglysingar' }}
                            >
                                Hafa samband
                            </Button>
                        }
                    />
                </div>
                <div className="section__body">
                    <div className="mini_shell">
                        <h2 id="Advertise_FrontPageAds">Forsíða</h2>
                        <div className="augl-section">
                            <div className="augl-table">
                                <AdsTable data={dataAdOne} />
                                <p className='paragraph-small--italic'>
                                    * Þegar Langi er keyptur þarf einnig að skila inn stærð 400x80px fyrir mobile birtingu.
                                </p>
                            </div>
                            <div className='img-container'>
                                <img
                                    src='https://cdn.livemarketdata.com/Images/ad_forsida.svg'
                                    alt='ad1'
                                    className='img-ad-1'
                                    width="510"
                                    height="549"
                                />
                            </div>
                        </div>
                            
                        <h2 id="Advertise_MarketPageAds">Markaðurinn</h2>
                        <div className='augl-section'>
                            <div className="augl-table">
                                <h4>Forsíða hlutabréfa, stök hlutabréf, stök skuldabréf, vísitölur, gjaldmiðlar, sjóðir og séreignarsjóðir.</h4>
                                <AdsTable data={dataAdTwo} />
                            </div>
                            <div className='img-container'>
                                <img
                                    src='https://cdn.livemarketdata.com/Images/ad_markadurinn.svg'
                                    alt='ad1'
                                    className='img-ad-1'
                                    width="510"
                                    height="427"
                                />
                            </div>
                        </div>
                        <h2 id="Advertise_MarketPageAds">Keldan App</h2>
                        <div className='augl-section'>
                            <div className="augl-table">
                                <AdsTable data={dataAdThree} />
                            </div>
                            <div className='img-container'>
                                <img
                                    src='https://cdn.livemarketdata.com/Images/ad_keldanApp.svg'
                                    alt='ad1'
                                    className='img-ad-1'
                                    width="194"
                                    height="350"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Advertise;

const AdsTable: React.FC<{ data: IAdData[] }> = ({
    data
}) => {
    return (
        <Table
            columns={[{
                renderCell: ({ name }) => name,
                textAlign: 'left',
                title: 'Nafn'
            }, {
                renderCell: ({ location }) => location,
                textAlign: 'left',
                title: 'Staðsetning'
            }, {
                renderCell: ({ size }) => size,
                textAlign: 'right',
                title: 'Stærð'
            }, {
                renderCell: ({ price1Week }) => price1Week,
                textAlign: 'right',
                title: 'Verð  1 vika'
            }, {
                renderCell: ({ price4Week }) => price4Week,
                textAlign: 'right',
                title: 'Verð  4 vikur'
            }, {
                renderCell: ({ maxAdvertisers }) => maxAdvertisers,
                textAlign: 'right',
                title: <p>hámarksfj.<br/> auglýsenda</p>
            }]}
            data={data}
            tableSize="lg"
        />
    )
}
