// Package imports:
import React, { memo, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons';
// Component imports:
import Link from '../../ui-elements/Link/Link';
import Label from '../../ui-elements/Label/Label';
// Service imports:
import { getNewsDateOrTime, gtmPush, newsTypeMap } from '../../services/utils';
// Type imports:
import { INewsLmdNewsItem } from '../../types/NewsTypes';

interface IProps {
    item: INewsLmdNewsItem,
    isNew: boolean, // the yellow notifier next to the title
    isHighlighted: boolean, // The highlighted color.
    isSeen: boolean,
    onOpen(): void
}

const NewsItem: React.FC<IProps>= ({
    item: {
        title,
        body,
        publish_date,
        source,
        fullSource,
        link,
        // paywall,
        feedMeta
    },
    isNew,
    isHighlighted,
    isSeen,
    onOpen
}) => {
    const [ isOpen, setIsOpen ] = useState(false);

    const expandNews = () => {
        setIsOpen(!isOpen);
        onOpen();
    }

    useEffect(() => {
        setIsOpen(false);
    }, [title]);

    const isExchangeNotice = feedMeta?.feedType === 'exchange_notice';

    return (
        <div
            className={cx('news-item', {
                'is-open': isOpen,
                'is-seen': isSeen,
                'is-highlighted': isHighlighted
            })}
        >
            <div
                className="news-item__head"
                onClick={() => {expandNews(); gtmPush('NewsItemClick', title ?? 'Title is null', (!isOpen) ? 'Opened' : 'Closed')}}
            >
                <div
                    className="news-item__trigger"
                >
                    {isExchangeNotice && (
                        <FontAwesomeIcon icon={faBullhorn} className='news-item__icon' />
                    )}
                    <span className="news-item__title">
                        {/* {paywall && <FontAwesomeIcon icon={faLock} className='news-lock-icon' />} */}
                        {title}
                    </span>
                </div>


                <div className="news-item__meta">
                    <div className="notify-wrapper">
                        {!isSeen && isNew && (
                            <div className="news-item__notify"></div>
                        )}
                    </div>

                    <span className='news-item__source'>{feedMeta?.feedSymbol ?? source?.split('_')[0]}</span>

                    <span className="news-item__time">
                        {publish_date ? getNewsDateOrTime(new Date(publish_date)) : '-'}
                    </span>
                </div>
            </div>

            <AnimateHeight
                className="news-item__content"
                duration={300}
                height={isOpen ? 'auto' : 0}
            >
                <div className="news-item__body">
                    <div
                        className={cx("news-item__entry", { 'trim-body': isExchangeNotice && fullSource?.includes('GlobeNewswire') })}
                        dangerouslySetInnerHTML={{__html: body ?? 'Ekki fundust frekari upplýsingar um frétt'}}
                    />

                    <div className="news-item__actions">
                        <Link
                            targetBlank
                            url={link ?? '#'}
                            linkSize='12'
                            icon='forward'
                            onClick={() => gtmPush('NewsItemClick', title ?? 'Title is null', 'Read')}
                        >
                            Skoða frétt
                        </Link>
                        <div className="news-item__labels">
                            {(feedMeta?.feedName || fullSource) && <Label
                                labelType='origin'
                                text={feedMeta?.feedName ?? fullSource ?? ''}
                                size='sm'
                            />}
                            {(feedMeta?.feedType) && <Label
                                labelType={isExchangeNotice ? 'beta' : 'origin'}
                                text={newsTypeMap[feedMeta.feedType]}
                                size='sm'
                            />}
                        </div>
                    </div>
                </div>
            </AnimateHeight>
        </div>
    )
}

export default memo(NewsItem);