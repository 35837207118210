// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
// Service imports:
import { GET_KELDAN_API_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { Fetched } from '../../../types/Types';
import { IKeldanApiResponse,  } from '../../../types/KeldanTypes';
import { ICompanyViewModel } from '../../../types/CompanyTypes';

interface ICompanyAwards {
    excellent2017: boolean
    excellent2018: boolean
    excellent2019: boolean
    excellent2020: boolean
    excellent2021: boolean
    excellent2022: boolean
    excellent2023: boolean,
    excellent2024: boolean
}
const url = "https://cdn.livemarketdata.com/Images/Excellent/"
const CompanyAwards: React.FC<ICompanyViewModel> = ({
    id
}) => {
    const [data, setData] = useState<Fetched<ICompanyAwards>>(null);
    const fetchCompanyAwardsData = async () => {
        try {
            const url = `${GET_KELDAN_API_URL()}/Company/GetCompanyAwards/${id}`;
            const headers = {
                'Content-Type': 'application/json; charset=utf-8'
            };
            
            const response = await fetch(url, {
                method: 'GET',
                headers
            });
            if (response.ok) {
                const responseBody: IKeldanApiResponse<ICompanyAwards> = await response.json();
                if (responseBody.success) {
                    if(responseBody.result) setData(responseBody.result);
                } else {
                    const { message } = responseBody;
                    if (message) {
                        setData(new Error(message));
                    } else {
                        setData(new Error(ErrorMessages.ErrorInRequest));
                    }
                }
            } else {
                setData(new Error(ErrorMessages.RequestFailed));
            }
        } catch (e) {
            setData(new Error(ErrorMessages.OtherShort));
        }
    }
   
    useEffect(() => {
        fetchCompanyAwardsData();
    }, [])

    const years = useMemo(() => {
        if (data === null || data instanceof Error) {
          return null;
        }
        return findYearRange(data);
      }, [data]);

      function findYearRange(data: ICompanyAwards): string | null {
        let startYear: number | null = null;
        let endYear: number | null = null;
      
        for (let year = new Date().getFullYear(); year >= 2017; year--) {
          const prop = `excellent${year}`;
          if ((data as any)[prop]) {
            if (endYear === null) {
              endYear = year; // Set endYear to the highest year with true value
            }
            startYear = year; // Set startYear to the current year with true value
          } else if (startYear !== null) {
            // If a true value was found earlier, and the current year is false, break
            break;
          }
        }
      
        if (startYear === endYear) {
          // If there's only one year, return it as a string
          return startYear !== null ? startYear.toString() : null;
        }
      
        return startYear !== null && endYear !== null
          ? `${startYear}-${endYear}`
          : null;
      }
      

    return <div className="KCL_company-awards">
        {data === null || years === null
            ? null
            : <a href='/Lists/Excellent'>
                <img
                    src={`${url}fyrirmyndar${years}.svg`}
                    alt={`Fyrirmyndarfyrirtæki í rekstri ${years}`}
                    width="800"
                    height="469"
                />
            </a>
        }
    </div>;
}

export default CompanyAwards;