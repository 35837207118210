// Package imports:
import React, { useEffect, useMemo, useState } from 'react';
// Component imports:
import Label from '../../../ui-elements/Label/Label';
import DisplayBox from '../../../ui-elements/DisplayBox/DisplayBox';
import Fyrirvari from '../../Fyrirvari/Fyrirvari';
import Link from '../../../ui-elements/Link/Link';
import Loading from '../../../ui-elements/Loading/Loading';
import LockIcon from '../../../ui-elements/LockIcon/LockIcon';
import Alert from '../../../ui-elements/Alert/Alert';
import ValuationCalculations from './ValuationCalculations';
import ValuationUnsuccessful from './ValuationUnsuccessful';
import SubscriptionBanner from '../../SubscriptionBanner/SubscriptionBanner';
import Tabs from '../../../ui-elements/Tabs/Tabs';
// Service imports:
import { quantityShorthandSplit, showValuation } from '../../../services/utils';
import { GET_API_LMD_URL } from '../../../services/config';
import { ErrorMessages } from '../../../services/errorMessages';
// Type imports:
import { ICompanyViewModel, ICompanyValuation } from '../../../types/CompanyTypes';

const CompanyValuation: React.FC<ICompanyViewModel> = ({
    id,
    isSubscriber,
    accessToken
}) => {
    const [ disclaimerOpen, setDisclaimerOpen ] = useState(false);
    const openDisclaimer = () => setDisclaimerOpen(true);
    const closeDisclaimer = () => setDisclaimerOpen(false);

    const [ activeTab, setActiveTab ] = useState(0);

    const [data, setData] = useState<ICompanyValuation | null | Error>(null);

    const fetchData = async () => {
        const url = `${GET_API_LMD_URL()}/v1/market_data/v1/company_valuation/${id}/valuation/v2`;

        fetch(url, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        .then(res => {
            if (res.ok) return res.json()
            else if (res.status === 404) throw new Error(ErrorMessages.NoDataFound);
            else throw new Error(ErrorMessages.RequestFailed);
        })
        .then(resBody => {
            setData(resBody);
        })
        .catch(err => {
            if (err instanceof Error) setData(err);
            else setData(new Error(ErrorMessages.RequestFailed));
        });
    }

    useEffect(() => {
        if (accessToken && isSubscriber) fetchData();
    }, [ accessToken, isSubscriber ]);

    const { displayNumberFCF, displayUnitFCF } = useMemo(() => {
        if (data instanceof Error || data === undefined || data == null) {
            return { displayNumberFCF: null, displayUnitFCF: null };
        }
        const { number, unit } = quantityShorthandSplit(data.equity_valuation, 0);
        return { displayNumberFCF: number, displayUnitFCF: unit };
    }, [data]);

    const { displayNumberEBITDA, displayUnitEBITDA, displayCurrency } = useMemo(() => {
        if (data instanceof Error || data === undefined || data == null) {
            return { displayNumberEBITDA: null, displayUnitEBITDA: null, displayCurrency: null };
        }
        const { number, unit } = quantityShorthandSplit(data.ebitda_equity_valuation, 0);
        return { displayNumberEBITDA: number, displayUnitEBITDA: unit, displayCurrency: data.currency === 'ISK' ? ' kr.' : ` ${data.currency}` };
    }, [data]);

    const shouldShowValuation = useMemo(() => {
        if (data instanceof Error) return 'error';
        if (data === null) return 'loading';
        return showValuation(data);
    }, [data])

    useEffect(() => {
        if (shouldShowValuation !== 'show' && shouldShowValuation !== 'loading') {
            setActiveTab(1);
        }
    }, [shouldShowValuation]);

    const getFCFTab = () => {
        if (shouldShowValuation === 'negative-fcf') {
            return <Alert
                type='info'
                headText='Fyrirtæki er með neikvætt frjálst sjóðstreymi'
                text='Ekki er unnt að birta verðmat fyrir fyrirtæki með neikvætt frjálst sjóðstreymi en sjá má útreikning hér fyrir neðan.'
            />
        }
        if (shouldShowValuation !== 'show') {
            return <Alert
                type='alert'
                headText='Ekki tókst að verðmeta fyrirtæki'
            />
        }
        return <div className='box-wrapper'>
            <div className='grey-box large-box'>
                <span className='grey-box__content'>
                    <span className='value'>
                        <span className='value-number'>{displayNumberFCF}</span>
                        <span className='value-unit'>{displayUnitFCF}</span>
                    </span>
                    <span className='title'>Virði eigin fjár</span>
                </span>
            </div>
        </div>
    }

    const getEBITDATab = () => {
        if (data instanceof Error || data === null || data.ebitda === null || data.ebitda_multiple === null || data.ebitda_equity_valuation === null || data.ebitda_equity_valuation <= 0) {
            return <Alert
                type='alert'
                headText='Ekki tókst að verðmeta fyrirtæki'
            />
        }
        if (data.ebitda <= 0) {
            return <Alert
                type='info'
                headText='Fyrirtæki er með neikvætt EBITDA'
            />
        }
        return <div className='box-wrapper'>
            <div className='grey-box large-box'>
                <span className='grey-box__content'>
                    <span className='value'>
                        <span className='value-number'>{displayNumberEBITDA}</span>
                        <span className='value-unit'>{displayUnitEBITDA} {displayCurrency}</span>
                    </span>
                    <span className='title'>Virði eigin fjár skv. EBITDA margfaldara</span>
                </span>
            </div>
        </div>
    }

    const displayValuation = () => {
        if (!isSubscriber) return <SubscriptionBanner size='sm' />
        if (data === null) return <Loading />
        return <Tabs
            tabs={[{
                id: 'fcf_valuation',
                label: 'Byggt á frjálsu sjóðstreymi',
                component: <>
                    <p className='extra-info'>Keldan reiknar frjálst sjóðstreymi út frá nýjasta ársreikningi.</p>
                    {getFCFTab()}
                </>
            }, {
                id: 'ebitda_valuation',
                label: 'Byggt á EBITDA',
                component: getEBITDATab()
            }]}
            redirectToTab={activeTab}
            onTabChange={setActiveTab}
        />
    }

    const leftLink = () => {
        if (isSubscriber) {
            if (activeTab === 0 && !(data instanceof Error) && data !== null && shouldShowValuation === 'negative-fcf') {
                return <ValuationCalculations id={id} data={data} accessToken={accessToken} />
            }
            if ((activeTab === 0 && shouldShowValuation !== 'show')
                || (activeTab === 1 &&
                    (data instanceof Error || data === null || data.ebitda === null
                        || data.ebitda_multiple === null || data.ebitda_equity_valuation === null || data.ebitda_equity_valuation <= 0
                    )
                )
            ) {
                return <ValuationUnsuccessful />
            }
        }
        return <Link
            className='more-link'
            url={`/Fyrirtaeki/Verdmat/${id}`}
            linkSize='14'
            disabled={!isSubscriber}
            icon='forward'
        >
            Sjá verðmat
        </Link>
    }

    return (
        <DisplayBox
            className='KCL_company-valuation'
            titleComponent={<>
                {!isSubscriber && <LockIcon />}
                Verðmat fyrirtækis
            </>}
            statusComponent={<Label labelType='beta' text='Beta' />}
            footerLeft={leftLink()}
            footerRight={
                <Fyrirvari
                    show={disclaimerOpen}
                    open={openDisclaimer}
                    close={closeDisclaimer}
                >
                    <p className='paragraph'>
                        Verðmatið er ekki fjárfestingarráðgjöf og skal ekki notað til ákvörðunartöku auk þess sem upplýsingar í verðmatinu kunna að vera rangar.
                        Þetta verðmat er gert til fróðleiks, samanburðar og skemmtunar en ekki til þess að sýna endanlegt sölu- eða kaupverð félags.<br />
                        Keldan ehf. ber enga ábyrgð á röngum upplýsingum eða fjárhagstjóni sem kann að verða vegna verðmatsins.
                    </p>
                </Fyrirvari>
            }
        >
            {displayValuation()}
        </DisplayBox>
    );
}

export default CompanyValuation;